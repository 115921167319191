import React, { Component } from 'react';
import InputText from '../common/InputText';
import SelectCity from '../common/SelectCity';
import SelectCitySayt from '../common/SelectCitySayt';
import SelectDate from '../common/SelectDate';
import SelectProvince from '../common/SelectProvince';
import SelectRegion from '../common/SelectRegion';
import SelectGender from '../common/SelectGender';

export default class DetailsPecPrivateFreelance extends Component {

    constructor(props) {
        super(props);

        // Indica se il dato è in modifica o creazione
        var dataModify = false;
        if (this.props.detailsPec.idOwner != 0) {
            //dataModify = true;
        }

        this.state = {
            dataModify: dataModify,
            spidmail: this.props.spidmail,
            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };
    }

    render() {
        return (
            <React.Fragment>

                <InputText
                    value={this.props.detailsPec.name || ''}
                    name="detailsPec_name"
                    label={this.props.config.dettaglio_nome}
                    handleChange={this.props.handleChange}
                    invalid_message={this.props.errorMessages.detailsPec_name}
                    maxLength={this.props.config.NAME_LENGTH_MAX}
                    required={true}
                    readOnly={this.state.dataModify || this.state.spidmail}
                />

                <InputText
                    value={this.props.detailsPec.surname || ''}
                    name="detailsPec_surname"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_cognome}
                    invalid_message={this.props.errorMessages.detailsPec_surname}
                    maxLength={this.props.config.SURNAME_MAX_LENGTH}
                    required={true}
                    readOnly={this.state.dataModify || this.state.spidmail}
                />

                <InputText
                    value={this.props.detailsPec.taxCode || ''}
                    name="detailsPec_taxCode"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_codice_fiscale}
                    invalid_message={this.props.errorMessages.detailsPec_taxCode}
                    maxLength={this.props.config.TAXCODE_MAX_LENGTH}
                    required={true}
                    readOnly={this.state.dataModify || this.state.spidmail}
                    forceUpper={true}
                />

                <InputText
                    value={this.props.detailsPec.street || ''}
                    name="detailsPec_street"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_indirizzo}
                    invalid_message={this.props.errorMessages.detailsPec_street}
                    maxLength={this.props.config.STREET_MAX_LENGTH}
                    required={true}
                />
                {/*
                <SelectRegion
                    value={this.props.detailsPec.region || ''}
                    valueCountry={this.props.detailsPec.country || 'IT'}
                    name="detailsPec_region"
                    handleChange={this.props.handleChange}
                    label={this.props.config.regione}
                    not_selected={this.props.config.selezionare_regione}
                    invalid_message={this.props.errorMessages.detailsPec_region}
                    required={true}
                />

                <SelectProvince
                    value={this.props.detailsPec.province || ''}
                    valueCountry={this.props.detailsPec.region || ''}
                    name="detailsPec_province"
                    handleChange={this.props.handleChange}
                    label={this.props.config.provincia}
                    invalid_message={this.props.errorMessages.detailsPec_province}
                    not_selected={this.props.config.selezionare_provincia}
                    required={true}
                />

                <SelectCity
                    valueProvince={this.props.detailsPec.province || ''}
                    value={this.props.detailsPec.city || ''}
                    name="detailsPec_city"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_city}
                    not_selected={this.props.config.selezionare_citta}
                    invalid_message={this.props.errorMessages.detailsPec_city}
                    required={true}
                />
*/}

                <SelectCitySayt
                    name="detailsPec_city"
                    value={this.props.detailsPec.city || ''}
                    label={this.props.config.dettaglio_city}
                    nameProvince="detailsPec_province"
                    valueProvince={this.props.detailsPec.province || ''}
                    labelProvince={this.props.config.provincia}
                    nameRegion="detailsPec_region"
                    not_selected={this.props.config.selezionare_citta}
                    invalid_message={this.props.errorMessages.detailsPec_city}
                    required={true}
                    hide_province={true}
                />
                <InputText
                    value={this.props.detailsPec.zip || ''}
                    name="detailsPec_zip"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_zip}
                    invalid_message={this.props.errorMessages.detailsPec_zip}
                    maxLength={this.props.config.ZIP_MAX_LENGTH}
                    required={true}
                />

                <InputText
                    value={this.props.detailsPec.mobileNumber || ''}
                    name="detailsPec_mobileNumber"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_cellulare}
                    placeHolder="+39"
                    invalid_message={this.props.errorMessages.detailsPec_mobileNumber}
                    maxLength={this.props.config.CELL_MAX_LENGTH}
                    helpText={this.props.config.dettaglio_cellulare_help}
                    required={true}
                />

                <InputText
                    value={this.props.detailsPec.email || ''}
                    name="detailsPec_email"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_email}
                    invalid_message={this.props.errorMessages.detailsPec_email}
                    maxLength={this.props.config.PEC_MAX_LENGTH}
                    helpText={this.props.config.dettaglio_email_help}
                    required={true}
                />

                {
                    (this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_PRIVATECITIZEN) && (

                        <React.Fragment>
                            <input type={"hidden"} value={"99999"}  name="detailsPec_birthZip" />
                            <input type={"hidden"} value={this.props.detailsPec.birthProvince}  name="detailsPec_birthProvince" />
                            <input type={"hidden"} value={this.props.detailsPec.region}  name="detailsPec_birthRegion" />
                            <SelectDate
                                name="detailsPec_birthDate"
                                selected={this.props.detailsPec.birthDate}
                                onChange={this.props.handleBirthDateChange}
                                invalid_message={this.props.errorMessages.detailsPec_birthDate}
                                label={this.props.config.dettaglio_data_nascita}
                                required={true}
                                readOnly={true}

                            />

                            <InputText
                                value={this.props.detailsPec.birthCity || ''}
                                name="detailsPec_birthCity"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_luogo_nascita}
                                //placeHolder='CAP di nascita'
                                invalid_message={this.props.errorMessages.detailsPec_birthCity}
                                required={true}
                                readOnly={true}
                            />
                            <SelectGender
                                value={this.props.detailsPec.gender || ''}
                                name="detailsPec_gender"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_gender}
                                config={this.props.config}
                                //placeHolder='CAP di nascita'
                                invalid_message={this.props.errorMessages.detailsPec_gender}
                                required={true}
                                readOnly={true}
                            />
                        </React.Fragment>
                    )
                }
                <input type="hidden" name="detailsPec_country" value={this.props.detailsPec.country || 'IT'} />

            </React.Fragment>
        );
    }
}

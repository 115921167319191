import React, { Component } from 'react';
import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import Servizio from '../common/Servizio';
import SelectCos from '../common/SelectCos';
import Modal from 'react-bootstrap/Modal';

export default class FormUpselling extends Component {

    constructor(props) {
        super(props);
        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        var buyServices = true;

        if (this.props.servicesConfigurator.upgraded_cos !== null) {
            this.props.servicesConfigurator.upgrade_cos_available[this.props.servicesConfigurator.upgraded_cos].quantita = 1;
            buyServices = false
        }

        // Gestione prezzi
        // let price = { "total": 0 };
        let price = this.refreshPrices();

        // Gestione submit disabled
        let submitDisabled = true;
        if (price['total'] > 0) {
            submitDisabled = false;
        }

        // Controllo degli errori ricevuti dal server e assegnamo i messaggi di errore
        this.state = {
            servicesConfigurator: this.props.servicesConfigurator,
            service_prices: price,
            token: token,
            pec_id: this.props.servicesConfigurator.pec_id,
            submitDisabled: submitDisabled,
            selectedValue: this.props.servicesConfigurator.upgraded_cos,
            errorMessages: {},
            buyServices: buyServices,
            show: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        //this.handleFocus = this.handleFocus.bind(this);
    }


    refreshPrices() {
        // Gestione prezzi
        let price = { "total": 0 };

        // servizi
        for (const [service_id, service] of Object.entries(this.props.servicesConfigurator.servizi)) {
            if (service.tipologia !== 'BASE') {

                if (service.codice_articolo == 'SERVIZIO_ARCHIVIO'
                    || service.codice_articolo == 'SERVIZIO_CONSERVAZIONE'
                    || service.codice_articolo == 'SERVIZIO_SMS'
                    || service.codice_articolo == 'SERVIZIO_REPORT'
                    || service.codice_articolo == 'SERVIZIO_MULTIUTENZA'
                    || service.codice_articolo == 'SERVIZIO_EMAILSCAN' ) {

                    //console.log(service.codice_articolo + ' -> ' + ' ' + JSON.stringify(service));
                    if (service.quantita_pre_upgrade == 0) {
                        price[service_id] = service.quantita * service.price_prezzo;
                        price['total'] += price[service_id];
                    }

                } else {

                    price[service_id] = service.quantita * service.price_prezzo;
                    price['total'] += price[service_id];
                }

            }
        }

        // cos per upgrade
        for (const [upgrade_cos_id, upgrade_cos_available] of Object.entries(this.props.servicesConfigurator.upgrade_cos_available)) {
            price[upgrade_cos_id] = upgrade_cos_available.quantita * upgrade_cos_available.price_prezzo;
            price['total'] += upgrade_cos_available.quantita * upgrade_cos_available.price_prezzo;
            //console.log("COS " + upgrade_cos_available.codice_articolo + ' -> ' + ' principale ' + upgrade_cos_available.principale);//+ JSON.stringify(upgrade_cos_available));
        }

        return price;
    }

    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {

        // Recupera nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const service_id = target.id;

        let sc = this.state.servicesConfigurator;

        // console.log("FormUpselling Form SERVICECONFIG " + JSON.stringify(sc));

        let price = this.state.service_prices;
        let current_service = sc.servizi[service_id];
        let errormessages = new Object();

        // Gestisce tutti i servizi
        switch (name) {
            case 'SERVIZIO_ARCHIVIO':
            case 'SERVIZIO_CONSERVAZIONE':
            case 'SERVIZIO_SMS':
            case 'SERVIZIO_REPORT':
            case 'SERVIZIO_MULTIUTENZA':
            case 'SERVIZIO_EMAILSCAN':
                let checked = target.checked;
                //console.log(" CHECKED  " + JSON.stringify(checked, null, '\t'));
                if (current_service.tipologia == 'ATTIVABILE') {
                    if (checked == true) {
                        current_service.quantita = 1;
                        price[target.id] = current_service.price_prezzo * current_service.quantita;
                    } else {
                        current_service.quantita = 0;
                        delete price[target.id];
                    }
                }
                if (current_service.tipologia == 'BASE') {
                    current_service.quantita = 0;
                    delete price[target.id];
                }
                break;

            case 'SPAZIO_AGGIUNTIVO_CASELLA':
            case 'SPAZIO_AGGIUNTIVO_ARCHIVIO':
            case 'SPAZIO_AGGIUNTIVO_CONSERVAZIONE':
                current_service.quantita = value;
                price[target.id] = current_service.price_prezzo * current_service.quantita;
                break;

            case 'NEW_COS':
                for (const [upgrade_cos_id, upgrade_cos_available] of Object.entries(this.props.servicesConfigurator.upgrade_cos_available)) {
                    sc.upgrade_cos_available[upgrade_cos_id].quantita = 0;
                    price[upgrade_cos_id] = 0;
                }
                if ('0' !== value) {
                    sc.upgrade_cos_available[value].quantita = 1;
                    price[value] = sc.upgrade_cos_available[value].price_prezzo;
                }
                this.state.selectedValue = value;
                break;
        }

        // Gestione dipendenze servizi
        for (const [dep_service_id, dep_service] of Object.entries(sc.servizi)) {
            let required_services = dep_service.require;
            if (required_services !== null && dep_service.quantita > 0) {
                for (const [required_service_id, required_service_name] of Object.entries(required_services)) {
                    //if (required_service_id == service_id && current_service.quantita == 0) {
                    //    // Azzera la quantità del servizio per il quale il servizo attuale è richiesto
                    //    console.log("AZZERARE IL SERVIZIO CHE LO RICHIEDE");
                    //    dep_service.quantita = 0;
                    //    sc.servizi[dep_service_id].quantita = 0;
                    //    console.log("CURRENT "+JSON.stringify(dep_service,null,'\t'));
                    //} else {
                    let required_service = sc.servizi[required_service_id];
                    if (required_service.quantita == 0) {
                        errormessages[dep_service_id] = {
                            error_type: 'required_service',
                            error_message: 'Attivare prima ' + required_service.descrizione_breve
                        };
                    }
                    //}

                    // Se il servizio è SPAZIO_AGGIUNTIVO_CONSERVAZIONE e non ho altri errori verifico se
                    // la quota di archivio è >= alla quota conservazione
                    if (current_service !== undefined) {
                        var archive_quota_service = sc.servizi[201];
                        var conservazione_quota_service = sc.servizi[203];
                        var archive_final_quota = archive_quota_service
                            ? +archive_quota_service.quantita + +archive_quota_service.quantita_pre_upgrade
                            : 0;
                        var conservazione_final_quota = conservazione_quota_service
                            ? +conservazione_quota_service.quantita + +conservazione_quota_service.quantita_pre_upgrade
                            : 0;

                        // console.log("TOT QUOTA: archive " + archive_final_quota + ' CONS ' + conservazione_final_quota);

                        if (conservazione_final_quota > archive_final_quota && !errormessages[203]) {
                            errormessages[203] = {
                                error_type: 'required_service',
                                error_message: 'Lo spazio totale di conservazione non può essere maggiore di quello dell\'archivio'
                            };
                        }
                    }

                }
            }

        }

        // Aggiorna il totale
        price.total = this.getTotal(price);

        let submitDisabled = true;
        if (price.total > 0 && Object.keys(errormessages).length == 0) {
            submitDisabled = false;
        }

        this.setState({
            servicesConfigurator: sc,
            service_prices: price,
            submitDisabled: submitDisabled,
            errorMessages: errormessages
        });
    }

    getTotal(price) {
        // Aggiorna il totale
        let totale = 0;
        for (const property in price) {
            if (property != 'total') {
                totale += price[property];
            }
        }
        return totale;
    }

    /**
     *
     *
     * @param {*} event
     */
    //handleFocus(event) {
    //    console.log("preso il focus");
    //}

    /**
     *
     * @param {*} event
     */
    componentDidMount() {
        //console.log("onload");
    }

    /**
     *
     * @param {*} event
     */
    handleSubmit(event) {
        // Applica la validazione
        //this.validationName();
        //event.preventDefault();

        // Invia la form
        this.myForm.current.submit();
    }

    /**
     *
     * @param {*} event
     */
    handleClose(event){
        this.setState({show:false});
        this.handleClick(event);
    }

    /**
     *
     * @param {*} event
     */
    handleShow(event){
        this.setState({show:true});
    }

    /**
     *
     * @param {*} event
     */
    handleClick(event) {
        // Recupera nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const id = target.id;

        let sc = this.state.servicesConfigurator;
        let price = this.state.service_prices;

        if (id == 'view_packet' || id == 'view_services') {
            var buyType;
            switch (id) {
                case 'view_services':
                    buyType = true;
                    // Deseleziona eventuali pacchetti selected
                    for (const [upgrade_cos_id, upgrade_cos_available] of Object.entries(this.props.servicesConfigurator.upgrade_cos_available)) {
                        sc.upgrade_cos_available[upgrade_cos_id].quantita = 0;
                        price[upgrade_cos_id] = 0;
                    }
                    this.setState({ selectedValue: 0 });
                    break;
                case 'view_packet':
                    buyType = false
                    // Deseleziona tutti i servizi
                    for (const [service_id, service] of Object.entries(sc.servizi)) {
                        if(service.tipologia != 'BASE'){
                            service.quantita = 0;
                            delete price[service_id];
                        }
                    }

                    break;
            }

            price.total = this.getTotal(price);

            this.setState({
                buyServices: buyType,
                servicesConfigurator: sc,
                service_prices: price,
                submitDisabled: price.total > 0 ? false : true,
            });
        }
    }

    ElencoServizi(props) {
        //console.log("ElencoServizi >>> " + JSON.stringify(props.errorMessages));
        return (
            <React.Fragment>
                {props.keys.map((item_id) => (
                    <Servizio
                        key={item_id.toString()}
                        servizio={props.servizi[item_id]}
                        handleChange={props.handleChange}
                        error_messages={item_id in props.errorMessages ? props.errorMessages[item_id] : {}}
                        checked={props.servizi[item_id].quantita > 0 ? 'checked' : ''}
                        disabled={props.servizi[item_id].tipologia == 'BASE' ? true : false}
                        required={true}
                        total_price={props.prezzi}
                        config={props.config}
                    />
                ))}
            </React.Fragment>
        );
    }

    ElencoCosUpgrade(props) {
        return (
            <React.Fragment>
                <SelectCos
                    cos={props.upgrade_cos_available}
                    handleChange={props.handleChange}
                    error_messages={props.errorMessages}
                    selectedValue={props.selectedValue}
                    disabled={props.keys.length == 0 ? true : false}
                    required={true}
                    service_prices={props.prezzi}
                    config={props.config}
                    name={'NEW_COS'}
                />
            </React.Fragment>
        );
    }

    render() {
        //console.log("ElencoServizi >>> " + JSON.stringify(this.state.errorMessages));
        return (
            <React.Fragment>
                <div className="upselling_container">
                    <div className="container">
                        <form action={this.props.config.url_upselling_recap} method="post" className="" onSubmit={this.handleSubmit} ref={this.myForm}>

                            <input type="hidden" name="_token" value={this.state.token} />
                            <input type="hidden" name="pec_id" value={this.state.pec_id} />

                            {Object.keys(this.state.servicesConfigurator.upgrade_cos_available).length > 0 && this.state.buyServices === true && (
                                <React.Fragment>
                                    <small>Non è al momento possibile acquistare contemporaneamente servizi e pacchetti di servizi</small><br/>
                                    <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Attenzione</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Passando alla selezione dei pacchetti i servizi attualmente selezionati verranno rimossi dal carrello, continuare?</Modal.Body>
                                        <Modal.Footer>
                                            <button type="button" className="btn btn-secondary" onClick={this.handleClose}>Chiudi</button>
                                            <button type="button" className="btn btn-primary" onClick={this.handleClose} id="view_packet">Vai ai pacchetti</button>
                                        </Modal.Footer>
                                    </Modal>
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link active disabled" id="view_services" href="#">Servizi</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={this.handleShow} id="view_packet" href="#" style={{borderColor: '#dee2e6 #dee2e6'}}>Pacchetti di servizi</a>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            )}

                            {Object.keys(this.state.servicesConfigurator.upgrade_cos_available).length > 0 && this.state.buyServices !== true && (
                                <React.Fragment>
                                    <small>Non è al momento possibile acquistare contemporaneamente servizi e pacchetti di servizi</small><br/>
                                    <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Attenzione</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Passando alla selezione dei servizi il pacchetto attualmente selezionato verrà rimosso dal carrello, continuare?</Modal.Body>
                                        <Modal.Footer>
                                            <button type="button" className="btn btn-secondary" onClick={this.handleClose}>Chiudi</button>
                                            <button type="button" className="btn btn-primary" onClick={this.handleClose} id="view_services">Vai ai servizi</button>
                                        </Modal.Footer>
                                    </Modal>

                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={this.handleShow} id="view_services" href="#" data-toggle="modal" data-target="#servicesModal" style={{borderColor: '#dee2e6 #dee2e6'}}>Servizi</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active disabled" id="view_packet" href="#">Pacchetti di servizi</a>
                                        </li>
                                    </ul>
                                    <this.ElencoCosUpgrade
                                        keys={Object.keys(this.state.servicesConfigurator.upgrade_cos_available)}
                                        upgrade_cos_available={this.state.servicesConfigurator.upgrade_cos_available}
                                        prezzi={this.state.service_prices}
                                        handleChange={this.handleChange}
                                        errorMessages={this.state.errorMessages}
                                        config={this.props.config}
                                        selectedValue={this.state.selectedValue}
                                    />
                                </React.Fragment>
                            )}

                            {(this.state.buyServices == true) && (
                                <React.Fragment>
                                    <this.ElencoServizi
                                        keys={Object.keys(this.state.servicesConfigurator.servizi)}
                                        servizi={this.state.servicesConfigurator.servizi}
                                        prezzi={this.state.service_prices}
                                        handleChange={this.handleChange}
                                        errorMessages={this.state.errorMessages}
                                        config={this.props.config}
                                    />
                                </React.Fragment>
                            )}

                            <div className="my-1 p-2 bg-light">
                                <input type="hidden" name="total" value={this.state.service_prices.total} />

                                <div className="form-row py-2">
                                    <div className="col ml-2 text-right">
                                        {this.props.config.servizi_totale_iva_esclusa}:
                                    </div>
                                    <div className="col-sm-2 text-left" id="grand_total">
                                        {this.state.service_prices.total}&nbsp;&euro;
                                    </div>
                                </div>

                            </div>
                            <div className="py-2 text-center">
                                <button type="submit" className="btn btn-primary" disabled={this.state.submitDisabled}>
                                    {this.props.config.servizi_avanti}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
} // fine classe FormUpselling


if (document.getElementById('form_upselling')) {
    ReactDOM.render(<FormUpselling config={config} errors={errors} servicesConfigurator={servicesConfigurator} />, document.getElementById('form_upselling'));
}
